import { ContractType } from '../../../../Shared.Frontend/constants/index';
import { FormBlockType } from 'pages/dashboard/types';
import { Paths } from 'constants/routes';
import { paymentMethodName } from 'constants/payments';
import { ValueOf } from '../../../../Shared.Frontend/types/utils';
import { getExternalLink } from 'utils/getExternalLink';
import { WsFlowsBelangrijkeDocumenten, WsFlowsBetaaldatumWijzigen, WsFlowsBetaalmanier, WsFlowsBetalingsregelingAanvragen, WsFlowsBezwaarMakenHuurverhoging, WsFlowsFacturen, WsFlowsHuurEnBetalingen, WsFlowsHuurOpzeggen, WsFlowsHuurverlagingAanvragen, WsFlowsKlachtOverlastMelden, WsFlowsKlussenInJeWoning, WsFlowsMedebewonerInschrijven, WsFlowsMedebewonerUitschrijven, WsFlowsMijnBedrijfsruimte, WsFlowsMijnHuishouden, WsFlowsMijnParkeerplaats, WsFlowsMijnWoning, WsFlowsNaamplaatjeAanvragen, WsFlowsOngedierteMelden, WsFlowsRekeningnummerWijzigen, WsFlowsServiceabonnement, WsFlowsSleutelAanvragen, WsFlowsToestemmingGevelreclame, WsFlowsVerhuurdersverklaringAanvragen, WsFlowsVerkeerdGebruik, WsFlowsVoorschotStookkostenWijzigen, WsFlowsZorgOmJeBuur } from '@woonstad/design-system';
import Repair from './icons/main-flows/Repair';

type ContractTypeMap = Record<
	ContractType,
	{
		tabs: Array<{
			title: string;
			testid?: string;
			icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | React.FunctionComponent<React.RefAttributes<SVGSVGElement>>;
			path: ValueOf<typeof Paths>;
			formBlocksCol1: Array<FormBlockType>;
			formBlocksCol2: Array<FormBlockType>;
		}>;
	}
>

export const DASHBOARD_CONTRACT_TYPE_MAP: ContractTypeMap = {
	[ContractType.HOME]: {
		tabs: [
			{
				title: 'In en om mijn woning',
				testid: 'woonruimteTab',
				icon: WsFlowsMijnWoning,
				path: Paths.DASHBOARD_WOONRUIMTE,
				formBlocksCol1: [
					{
						type: 'collection',
						title: 'Reparaties en klussen',
						testid: 'reparatiesEnKlussenTitle',
						inner: [
							{
								type: 'ingediende-reparaties',
								when: 'always',
							},
							{
								type: 'buttons',
								title: null,
								buttons: [
									{
										title: 'Reparatie melden',
										testid: 'repairRequestLink',
										icon: Repair,
										link: Paths.REPARATIE_MELDEN,
										when: 'always',
									},
									{
										title: 'Klussen in je woning',
										testid: 'klussenInJeWoningLink',
										icon: WsFlowsKlussenInJeWoning,
										link: Paths.KLUSSEN_IN_JE_WONING,
										when: 'always',
									},
									{
										title: 'Serviceabonnement',
										testid: 'serviceContractLink',
										icon: WsFlowsServiceabonnement,
										link: (contract) => contract?.hasServiceContract ? undefined : Paths.SERCIVE_SUBSCRIPTION,
										subtitle: (contract) => contract?.hasServiceContract ? 'Aan' : 'Uit',
										when: ({ flowAvailability }): boolean => {
											return flowAvailability?.find((flow) => flow.name === 'serviceSubscription')?.available ?? true;
										},
									},
								],
							},
						],
					},
					{
						type: 'buttons',
						title: 'Sleutel en naamplaatje',
						testid: 'sleutelEnNaamplaatjeTitle',
						buttons: [
							{
								title: 'Sleutel aanvragen',
								testid: 'requestKeyLink',
								icon: WsFlowsSleutelAanvragen,
								link: Paths.REQUEST_KEY,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestKey')?.available ?? true;
								},
							},
							// {
							// 	title: 'Sleutel aanvragen (nieuw)',
							// 	testid: 'requestKeyLink',
							// 	icon: WsFlowsSleutelAanvragen,
							// 	link: Paths.SLEUTEL_AANVRAGEN,
							// 	when: ({ flowAvailability, featureFlagsPresent }): boolean => {
							// 		return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('sleutel-aanvragen')
							// 			&& (flowAvailability?.find((flow) => flow.name === 'requestKey')?.available ?? true);
							// 	},
							// },
							{
								title: 'Naamplaatje aanvragen',
								testid: 'requestNametagLink',
								icon: WsFlowsNaamplaatjeAanvragen,
								link: Paths.REQUEST_NAMETAG,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestNametag')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'buttons',
						title: 'Overlast, klacht of zorg',
						testid: 'klachtOfOverlastTitle',
						buttons: [
							{
								title: 'Overlast melden',
								testid: 'overlastMeldenLink',
								icon: WsFlowsKlachtOverlastMelden,
								link: Paths.OVERLAST_MELDEN,
								when: 'always',
							},
							{
								title: 'Ongedierte melden',
								testid: 'ongedierteMeldenLink',
								icon: WsFlowsOngedierteMelden,
								link: Paths.ONGEDIERTE_MELDEN,
								when: ({ flowAvailability, featureFlagsPresent }): boolean => {
									return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('ongedierte-melden') && (flowAvailability?.find((flow) => flow.name === 'ongedierteMelding')?.available ?? true);
								},
							},
							{
								title: 'Verkeerd gebruik woning melden',
								testid: 'woonfraudeMeldenLink',
								icon: WsFlowsVerkeerdGebruik,
								link: Paths.WOONFRAUDE_MELDEN,
								when: 'always',
							},
							{
								title: 'Zorg om je buur melden',
								testid: 'zorgOmJeBuurMeldenLink',
								icon: WsFlowsZorgOmJeBuur,
								link: Paths.ZORG_OM_JE_BUUR,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'zorgenOmBuur')?.available ?? true;
								},
							},
						],
					},
				],
			},
			{
				title: 'Huur en betalingen',
				testid: 'huurBetalingenTab',
				icon: WsFlowsHuurEnBetalingen,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Huur betalen',
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: 'Betaalmanier',
								testid: 'paymentMethodLink',
								subtitle: (contract) => paymentMethodName(contract?.paymentMethod),
								icon: WsFlowsBetaalmanier,
								link: () => undefined,
								when: 'always',
							},
							{
								title: 'Rekeningnummer wijzigen',
								testid: 'changeIbanLink',
								icon: WsFlowsRekeningnummerWijzigen,
								link: () => Paths.CHANGE_IBAN,
								when: 'always',
							},
							{
								title: 'Betaaldatum wijzigen',
								testid: 'changeDirectDebitDateLink',
								icon: WsFlowsBetaaldatumWijzigen,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							{
								// @TODO This availability is not in sync with logic implemented in AdvancementAdjusted.tsx line 120
								title: 'Voorschot stookkosten wijzigen',
								testid: 'advancementAdjustmentLink',
								icon: WsFlowsVoorschotStookkostenWijzigen,
								link: (contract) => Paths.ADVANCEMENT_ADJUSTMENT.replace(':contractId', String(contract.id)),
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'adjustAdvancement')?.available ?? true;
								},
							},
							{
								title: 'Betalingsregeling aanvragen',
								testid: 'requestPaymentPlanLink',
								icon: WsFlowsBetalingsregelingAanvragen,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
							{
								title: 'Huurverlaging aanvragen',
								testid: 'requestRentReductionLink',
								icon: WsFlowsHuurverlagingAanvragen,
								link: Paths.RENT_REDUCTION_REQUEST,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestRentReduction')?.available ?? true;
								},
							},
							{
								title: 'Bezwaar maken huuraanpassing',
								testid: 'opposeRentIncreaseLink',
								icon: WsFlowsBezwaarMakenHuurverhoging,
								link: Paths.RENT_INCREASE_OBJECTION,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'opposeRentIncrease')?.available ?? true;
								},
							},
							// {
							// 	title: 'Wijzigen bankrekening',
							// 	icon: Pencil,
							// 	link: Paths.CHANGE_DIRECT_DEBIT_DATE,
							// 	when: 'always',
							// },
							// {
							// 	title: 'AcceptEasy wisselen',
							// 	icon: CreditCheck,
							// 	link: Paths.CHANGE_DIRECT_DEBIT_DATE,
							// 	when: 'always',
							// },
						],
					},
					{
						type: 'buttons',
						title: 'Opzeggen en verhuurdersverklaring',
						testid: 'opzeggenEnVerhuurdersverklaringTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: WsFlowsHuurOpzeggen,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
							{
								title: 'Verhuurdersverklaring aanvragen',
								testid: 'requestRentalReferenceLetterLink',
								icon: WsFlowsVerhuurdersverklaringAanvragen,
								link: Paths.RENTAL_REFERENCE_LETTER,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestRentalReferenceLetter')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Mijn huishouden',
				testid: 'huishoudenTab',
				icon: WsFlowsMijnHuishouden,
				path: Paths.DASHBOARD_HUISHOUDEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Wijziging doorgeven',
						testid: 'wijzigingDoorgevenTitle',
						buttons: [
							{
								title: 'Medebewoner inschrijven',
								testid: 'addRoommateLink',
								icon: WsFlowsMedebewonerInschrijven,
								link: Paths.ADD_ROOMMATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'addRoommate')?.available ?? true;
								},
							},
							{
								title: 'Een inwoner vertrekt',
								subtitle: 'Deze persoon woont bij jou, maar is niet verantwoordelijk voor de huur.',
								testid: 'inwonerVertrektLink',
								icon: WsFlowsMedebewonerUitschrijven,
								link: Paths.INWONER_VERTREKT,
								when: ({ flowAvailability, featureFlagsPresent }): boolean => {
									// TODO: Add availability check when BE is ready, remove featureFlagsPresent check
									return Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('inwoner-vertrekt');
									// && (flowAvailability?.find((flow) => flow.name === 'inwonerVertrekt')?.available ?? true);
								},
							},
							// {
							// 	title: 'Afstandsverklaring',
							// 	icon: HouseLeave,
							// 	link: Paths.DASHBOARD,
							// 	when: 'always',
							// },
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'bewoners',
						when: 'always',
					},
				],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: WsFlowsBelangrijkeDocumenten,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.PARKING]: {
		tabs: [
			{
				title: 'In en om mijn parkeerplaats',
				testid: 'parkeerplaatsTab',
				icon: WsFlowsMijnParkeerplaats,
				path: Paths.DASHBOARD_PARKEERPLAATS,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Klacht of overlast',
						testid: 'klachtOfOverlastTitle',
						buttons: [
							{
								title: 'Klacht of overlast melden',
								testid: 'overlastMeldenLink',
								icon: WsFlowsKlachtOverlastMelden,
								link: getExternalLink('COMPLAINT_OR_ANNOYANCE'),
								when: 'always',
							},
						],
					},
				],
				formBlocksCol2: [],
			},
			{
				title: 'Huur & betalingen',
				testid: 'huurBetalingenTab',
				icon: WsFlowsHuurEnBetalingen,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Huur betalen',
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: 'Betaaldatum wijzigen',
								testid: 'changeDirectDebitDateLink',
								icon: WsFlowsBetaaldatumWijzigen,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							{
								title: 'Rekeningnummer wijzigen',
								testid: 'changeIbanLink',
								icon: WsFlowsRekeningnummerWijzigen,
								link: () => Paths.CHANGE_IBAN,
								when: 'always',
							},
							{
								title: 'Betalingsregeling aanvragen',
								testid: 'requestPaymentPlanLink',
								icon: WsFlowsBetalingsregelingAanvragen,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
						],
					},
					{
						type: 'buttons',
						title: 'Opzeggen',
						testid: 'opzeggenTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: WsFlowsHuurOpzeggen,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: WsFlowsBelangrijkeDocumenten,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.BUSINESS]: {
		tabs: [
			{
				title: 'In en om mijn bedrijfsruimte',
				testid: 'businessTab',
				icon: WsFlowsMijnBedrijfsruimte,
				path: Paths.DASHBOARD_BUSINESS,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Reparaties',
						testid: 'reparatiesTitle',
						buttons: [
							{
								title: 'Reparatie melden',
								testid: 'repairRequestLink',
								icon: Repair,
								link: Paths.REPARATIE_MELDEN,
								when: 'always',
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'buttons',
						title: 'Iets ophangen aan de gevel',
						testid: 'ietsOphangenAanDeGevel',
						buttons: [
							{
								title: 'Toestemming gevelreclame, rolluik of zonnescherm',
								testid: 'toestemmingGevelreclame',
								icon: WsFlowsToestemmingGevelreclame,
								link: Paths.GEVELRECLAME_ROLLUIKEN_ZONWERING,
								when: ({ featureFlagsPresent }) =>
									Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('toestemming-gevel'),
							},
						],
					},
				],
			},
			{
				title: 'Huur & betalingen',
				testid: 'huurBetalingenTab',
				icon: WsFlowsHuurEnBetalingen,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Huur betalen',
						testid: 'requestPaymentPlanLink',
						buttons: [
							{
								title: 'Betalingsregeling aanvragen',
								icon: WsFlowsBetalingsregelingAanvragen,
								link: Paths.BETALINGSREGELING_BEDRIJFSRUIMTE,
								when: ({ flowAvailability, featureFlagsPresent }) =>
									Array.isArray(featureFlagsPresent) && featureFlagsPresent.includes('betalingsregeling-bedrijfsruimte') && (flowAvailability?.find((flow) => flow.name === 'betalingsRegelingBedrijfsRuimte')?.available ?? true),
							},
							{
								title: 'Rekeningnummer wijzigen',
								testid: 'changeIbanLink',
								icon: WsFlowsRekeningnummerWijzigen,
								link: () => Paths.CHANGE_IBAN,
								when: 'always',
							},
						],

					},
					{
						type: 'buttons',
						title: 'Opzeggen',
						testid: 'opzeggenTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: WsFlowsHuurOpzeggen,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Facturen',
				testid: 'facturenTab',
				icon: WsFlowsFacturen,
				path: Paths.DASHBOARD_FACTUREN,
				formBlocksCol1: [
					{
						type: 'facturen',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: WsFlowsBelangrijkeDocumenten,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.OWNER]: { tabs: [] },
};
