import InfoBlue from 'assets/svg/rich/info-blue.svg?react';
import './Footer.scss';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import { getExternalLink } from 'utils/getExternalLink';
import { TextLink, Typography } from '@woonstad/design-system';

export const Footer = () => {
	return (
		<footer className='relative max-w-[1200px] mx-auto px-[1.875rem] max-sm:p-0'>
			<div className={'px-4 pb-4 sm:px-8 sm:pb-8 bg-footer md:flex md:justify-between'}>
				<div className='translate-y-[-25%] flex max-md:flex-col'>
					<InfoBlue />
					<div className='mt-2 md:mt-12'>
						<Typography
							variant='title-body'
							textColorClassname='text-base-primary-on-light'
						>
							Kunnen we je helpen?
						</Typography>
						<TextLink
							iconRight={ArrowForward}
							size='small'
						>
							<a href={getExternalLink('CONTACT')}>Neem contact met ons op</a>
						</TextLink>
					</div>
				</div>
				<div className='flex justify-start gap-1 items-end mt-10 text-'>
					<Typography
						variant='label-small'
						className='text-base-secondary-on-light'
						type='span'
					>
						<a href={getExternalLink('WEBSITE')}>
							Woonstad Rotterdam
						</a>
						{' '}
						•
						{' '}
						<a href={getExternalLink('PRIVACY_STATEMENT')}>
							Privacy Statement
						</a>
						{' '}
						•
						{' '}
						<a href={getExternalLink('USER_CONSENT_DISCLAIMER')}>
							Disclaimer
						</a>
					</Typography>
				</div>
			</div>
		</footer>
	);
};
