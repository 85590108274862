/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentInfo } from '../models/DocumentInfo';
import type { FileUploadData } from '../models/FileUploadData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @param contractId
     * @param idType
     * @param id
     * @param runNumber
     * @param fileName
     * @returns any OK
     * @throws ApiError
     */
    public static documentDownloadByContract(
        contractId: number,
        idType: string,
        id: number,
        runNumber: number,
        fileName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woonstad-portal/api/document/download/c-{contractId}-{idType}-{id}_{runNumber}/{fileName}',
            path: {
                'contractId': contractId,
                'idType': idType,
                'id': id,
                'runNumber': runNumber,
                'fileName': fileName,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * Retrieves a list of invoices.
     * @param contractId
     * @param from
     * @param to
     * @returns DocumentInfo OK
     * @throws ApiError
     */
    public static documentGetInvoices(
        contractId: number,
        from?: string,
        to?: string,
    ): CancelablePromise<Array<DocumentInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woonstad-portal/api/document/invoices/{contractId}',
            path: {
                'contractId': contractId,
            },
            query: {
                'from': from,
                'to': to,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                410: `Gone`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * Retrieves a list of invoices.
     * @param contractId
     * @param from
     * @param to
     * @returns string OK
     * @throws ApiError
     */
    public static documentDownloadInvoices(
        contractId: number,
        from?: string,
        to?: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woonstad-portal/api/document/invoices/{contractId}/download',
            path: {
                'contractId': contractId,
            },
            query: {
                'from': from,
                'to': to,
            },
            errors: {
                400: `BadRequest`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `NotFound`,
                410: `Gone`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param files
     * @returns boolean OK
     * @throws ApiError
     */
    public static documentUpload(
        files: FileUploadData,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/woonstad-portal/api/document/proof-of-registration',
            body: files,
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

    /**
     * @param contractId
     * @returns DocumentInfo OK
     * @throws ApiError
     */
    public static documentSelection(
        contractId: number,
    ): CancelablePromise<Array<DocumentInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/woonstad-portal/api/document/selection',
            query: {
                'contractId': contractId,
            },
            errors: {
                401: `Unauthorized`,
                424: `Dependency Failed`,
            },
        });
    }

}
