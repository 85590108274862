import { centerBoxedPublicRoutes, loginPublicRoutes, publicFullPageRoutes } from 'Routing';
import { useToken } from 'hooks/useToken';
import parseLink from 'utils/parseLink';
import { useEffect } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { useAccount } from '@azure/msal-react';
import { useCookies } from 'react-cookie';

const stripTrailingSlash = (str: string) => {
	return str.endsWith('/') ?
		str.slice(0, -1) :
		str;
};

/**
 * Make sure the user is redirected to the correct page after logging in.
 */
const useRedirectCallback = () => {
	const history = useHistory();
	const token = useToken();
	const query = new URLSearchParams(location.search);
	const callback = query.get('callback');

	const account = useAccount();
	const customerIdString = account?.idTokenClaims?.extension_CustomerIds;
	const customerIds = customerIdString?.split(',').length;
	const hasMultipleAccounts = (customerIds ?? 0) > 1;

	const [cookie] = useCookies(['customer-id']);
	const customerIdCookie = Boolean(cookie['customer-id']);

	useEffect(() => {
		if (hasMultipleAccounts && !customerIdCookie) {
			return;
		}

		if (token && callback) {
			const { isExternalLink } = parseLink(callback);
			if (isExternalLink) {
				window.location.replace(callback);
			} else {
				history.push(callback);
			}

			return;
		}

		const cleanPathName = stripTrailingSlash(location.pathname).toLowerCase();
		const publicRoutesWithRedirect = loginPublicRoutes.filter((route) => route.redirectIfLoggedIn).map((route) => route.path) as string[];
		const allPublicRoutes = [...loginPublicRoutes, ...centerBoxedPublicRoutes, ...publicFullPageRoutes];
		const publicRouteMatched = allPublicRoutes.find((route) => matchPath(location.pathname, {
			path: route.path,
		}));

		// Logged in and on a public route with redirect
		if (token && publicRoutesWithRedirect.includes(cleanPathName) && publicRouteMatched?.redirectIfLoggedIn) {
			history.replace(publicRouteMatched?.redirectIfLoggedIn);
			return;
		}
	}, [
		callback,
		customerIdCookie,
		hasMultipleAccounts,
		history,
		token,
	]);

	return { hasCallback: Boolean(callback) };
};

export default useRedirectCallback;
