import { OpenAPIConfig } from '../../../Shared.Frontend/generated-portal-woonstad/core/OpenAPI';
import { OpenAPI as OpenAPIPortalBE } from '../../../Shared.Frontend/generated-portal-woonstad/core/OpenAPI';
import { OpenAPI as OpenAPIContentDelivery } from '@woonstad/api-client-content-delivery';
import { OpenAPI as OpenAPIMaintenance } from '../../../Shared.Frontend/generated-maintenance/core/OpenAPI';
import { OpenAPI as OpenAPIAvailability } from '../../../Shared.Frontend/generated-availability/core/OpenAPI';
import { OpenAPI as OpenAPIAccount } from '../../../Shared.Frontend/generated-account/core/OpenAPI';
import { OpenAPI as OpenAPITrackAndTrace } from '../../../Shared.Frontend/generated-track-and-trace/core/OpenAPI';
import { OpenAPI as OpenAPIPayment } from '../../../Shared.Frontend/generated-payment/core/OpenAPI';
import { OpenAPI as OpenAPIPortal } from '../../../Shared.Frontend/generated-portal/core/OpenAPI';
import { OpenAPI as OpenAPIPicobello } from '@woonstad/api-client-picobello';
import { OpenAPI as OpenAPIWebcontracten } from '@woonstad/api-client-web-contracten';
import { OpenAPI as OpenAPIWebFinancien } from '@woonstad/api-client-web-financien';

const defaultTrackAndTracePathname = new URL(OpenAPITrackAndTrace.BASE).pathname;
const defaultWebcontractenPathname = new URL(OpenAPIWebcontracten.BASE).pathname;
const defaultWebFinancienPathname = new URL(OpenAPIWebFinancien.BASE).pathname;

export const openAPISet = <Property extends keyof OpenAPIConfig>(
	property: Property,
	value: { gateway: OpenAPIConfig[Property], okapi: OpenAPIConfig[Property] },
) => {
	// Gateway
	OpenAPIPortalBE[property] = value.gateway;
	OpenAPIContentDelivery[property] = value.gateway;
	OpenAPIMaintenance[property] = value.gateway;
	OpenAPIAvailability[property] = value.gateway;
	OpenAPIAccount[property] = value.gateway;
	OpenAPIPayment[property] = value.gateway;
	OpenAPIPortal[property] = value.gateway;
	OpenAPIPicobello[property] = value.gateway;

	// Okapi
	if (property === 'BASE' && value.okapi && typeof value.okapi === 'string') {
		const safeOkapiBase = new URL(value.okapi).origin;
		OpenAPITrackAndTrace.BASE = `${safeOkapiBase}${defaultTrackAndTracePathname}`;
		OpenAPIWebcontracten.BASE = `${safeOkapiBase}${defaultWebcontractenPathname}`;
		OpenAPIWebFinancien.BASE = `${safeOkapiBase}${defaultWebFinancienPathname}`;
	} else {
		OpenAPITrackAndTrace[property] = value.okapi;
		OpenAPIWebcontracten[property] = value.okapi;
		OpenAPIWebFinancien[property] = value.okapi;
	}
};

export const openAPIMutate = <Property extends keyof OpenAPIConfig>(
	property: Property,
	mutator: (value: OpenAPIConfig[Property]) => { gateway: OpenAPIConfig[Property], okapi: OpenAPIConfig[Property] },
) => {
	// Gateway
	OpenAPIPortalBE[property] = mutator(OpenAPIPortalBE[property]).gateway;
	OpenAPIContentDelivery[property] = mutator(OpenAPIContentDelivery[property]).gateway;
	OpenAPIMaintenance[property] = mutator(OpenAPIMaintenance[property]).gateway;
	OpenAPIAvailability[property] = mutator(OpenAPIAvailability[property]).gateway;
	OpenAPIAccount[property] = mutator(OpenAPIAvailability[property]).gateway;
	OpenAPIPayment[property] = mutator(OpenAPIPayment[property]).gateway;
	OpenAPIPortal[property] = mutator(OpenAPIPortal[property]).gateway;
	OpenAPIPicobello[property] = mutator(OpenAPIPicobello[property]).gateway;

	// Okapi
	OpenAPITrackAndTrace[property] = mutator(OpenAPITrackAndTrace[property]).okapi;
	OpenAPIWebcontracten[property] = mutator(OpenAPIWebcontracten[property]).okapi;
	OpenAPIWebFinancien[property] = mutator(OpenAPIWebFinancien[property]).okapi;
};
