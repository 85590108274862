import { useHistory } from 'react-router-dom';
import { useContractById, useContracts } from './store/useContracts';
import { useSessionStorage } from './useSessionStorage';
import { useEffect, useMemo } from 'react';
import { Paths } from 'constants/routes';
import { ValueOf } from '../../../Shared.Frontend/types/utils';
import { openAPIMutate } from 'utils/openAPI';

/**
 * This hook is used to get the contract to use for the flow. Use it on all the pages that require a contract to have been chosen.
 */
export const useContractToUse = (redirectToPath?: ValueOf<typeof Paths>) => {
	const [contractInStorage, setContractInStorage] = useSessionStorage<string | undefined>('chosen-contractId', undefined);

	const { contracts: allContracts, requesting, success, isError } = useContracts();

	// In some cases (Klussen in je woning) we want the user to select the correct contract before proceeding.
	// That's why we don't set a contract here if it isn't set in storage.
	const { contract: contractIsSelectedByUser } = useContractById(contractInStorage ?? '');

	const contractToUse = useMemo(() => contractIsSelectedByUser ?? allContracts?.[0], [allContracts, contractIsSelectedByUser]);

	useEffect(() => {
		openAPIMutate('HEADERS', (currentHeaders) => ({ gateway: { ...currentHeaders,
			'X-Contract-Id': contractToUse?.id?.toString() ?? '',
		}, okapi: {} }));
	}, [contractToUse?.id]);

	const history = useHistory();

	// If the contract in storage is not valid, remove it from storage
	useEffect(() => {
		if (!allContracts || allContracts.length === 0) return;

		const isContractInStorageValid = allContracts.some((contract) => contract.id?.toString() === contractInStorage);

		if (!isContractInStorageValid) {
			setContractInStorage(undefined);
		}
	}, [allContracts, contractInStorage, setContractInStorage]);

	// If no contract is found, redirect to the given path to choose a contract
	useEffect(() => {
		if (requesting || !success || !redirectToPath) {
			return;
		}

		if (!contractIsSelectedByUser) {
			history.push(redirectToPath);
		}
	}, [allContracts?.length, contractIsSelectedByUser, history, redirectToPath, requesting, success]);

	return {
		contractToUse,
		setContractInStorage,
		requesting,
		success,
		isError,
	};
};

