import { useEffect } from 'react';
import useQueryParams from './useQueryParams';
import { useSessionStorage } from './useSessionStorage';
import { openAPIMutate } from 'utils/openAPI';

const featureFlags = [
	'track-trace-links',
	'new-reparatie-melden',
	'betalingsregeling-bedrijfsruimte',
	'inwoner-vertrekt',
	'sleutel-aanvragen',
	'toestemming-gevel',
	'ongedierte-melden',
	'huurder-vertrekt',
] as const;

export type FeatureFlags = typeof featureFlags[number];

const defaultFlagValues: Record<FeatureFlags, boolean> = {
	'track-trace-links': false,
	'new-reparatie-melden': false,
	'betalingsregeling-bedrijfsruimte': false,
	'inwoner-vertrekt': false,
	'sleutel-aanvragen': true,
	'toestemming-gevel': false,
	'ongedierte-melden': false,
	'huurder-vertrekt': false,
};

const trueFlags = featureFlags.filter((flag) => defaultFlagValues[flag]);

const useFeatureFlagsInSessionStorage = () => {
	const query = useQueryParams();
	const [flags, setFlags] = useSessionStorage<FeatureFlags[]>('featureFlags', [...trueFlags]);

	// Set flags in session storage if they are present in the query
	useEffect(() => {
		const flagsInQuery = featureFlags.filter((flag) => query.has(flag));
		if (flagsInQuery.length > 0) {
			setFlags(flagsInQuery);
		}
	}, [query, setFlags]);

	useEffect(() => {
		const flagsToHeaders = flags.reduce((acc, flag) => ({
			...acc,
			[`Featureflag-${flag}`]: 'true',
		}), {} as Record<`Featureflag-${FeatureFlags}`, 'true'>);

		openAPIMutate('HEADERS', (currentHeaders) => ({
			gateway: {
				...currentHeaders,
				...flagsToHeaders,
			},
			okapi: { ...currentHeaders },
		}));
	}, [flags]);

	return flags;
};

/**
 * Checks if feature flag(s) are present
 */
export const useFeatureFlag = (featureFlagsPresent: FeatureFlags[]) => {
	const flagsInSession = useFeatureFlagsInSessionStorage();
	const query = useQueryParams();
	return featureFlagsPresent.some((flag) => query.has(flag) || flagsInSession.includes(flag));
};

/**
 * Returns all feature flags that are present
 */
export const useFeatureFlagsPresent = () => {
	const flagsInSession = useFeatureFlagsInSessionStorage();
	const query = useQueryParams();
	return featureFlags.filter((flag) => query.has(flag) || flagsInSession.includes(flag));
};
