import { lazy } from 'react';
import CenterBoxedContainer from 'components/center-boxed-container/CenterBoxedContainer';
import LanguageProvider from 'components/language-provider/LanguageProvider';
import { PageRoutesContainer } from 'components/page-routes-container/PageRoutesContainer';
import AuthPageTransition from 'components/transitions/AuthPageTransition';
import { Paths } from 'constants/routes';
import { useGoogleTagManagerVirtualPageView } from 'hooks/app/useGoogleTagManager';
import useScrollRestoration from 'hooks/app/useScrollRestoration';
import { useUserIsContractor } from 'hooks/store/useUserRoles';
import { FunctionComponent } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import InstallPwa from 'components/install-pwa/InstallPwa';
import useRedirectCallback from 'hooks/app/useRedirectCallback';
import { ValueOf } from '../../Shared.Frontend/types/utils';
import useMultiCustomerRouting from 'hooks/useMultiCustomerRouting';
import { FeatureFlags, useFeatureFlagsPresent } from 'hooks/useFeatureFlag';
import ChangeEmailMultiUser from 'pages/change-email-multi-user/ChangeEmailMultiUser';
import ChangeEmailMultiUserConfirmation from 'pages/change-email-multi-user/confirmation/ChangeEmailMultiUserConfirmation';
import { safeEnv } from 'utils/safeEnv';
import { RouteItem } from 'hooks/useAliasRouting';
import useAliasRouting from 'hooks/useAliasRouting';

const RequestKey = lazy(() => import('pages/request-key/RequestKey'));
const RequestKeyPaymentReturn = lazy(() => import('pages/request-key/payment-return/RequestKeyPaymentReturn'));

const LoggedOut = lazy(() => import('pages/logged-out/LoggedOut'));
const LoginFailed = lazy(() => import('pages/login-failed/LoginFailed'));

const ChooseAccount = lazy(() => import('pages/choose-account/ChooseAccount'));

const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const UserProfile = lazy(() => import('pages/user-profile/UserProfile'));

const AddRoommate = lazy(() => import('pages/add-roommate/AddRoommate'));
const AddRoommateConfirmation = lazy(() => import('pages/add-roommate/add-roommate-confirmation/AddRoommateConfirmation'));
const AddRoommateError = lazy(() => import('pages/add-roommate/add-roommate-error/AddRoommateError'));

const InwonerVertrekt = lazy(() => import('pages/inwoner-vertrekt/InwonerVertrekt'));
const InwonerVertrektSuccess = lazy(() => import('pages/inwoner-vertrekt/Success'));
const InwonerVertrektError = lazy(() => import('pages/inwoner-vertrekt/Error'));

const SleutelAanvragenFormKeuze = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenFormKeuze'));
const SleutelAanvragenFormDetails = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenFormDetails'));
const SleutelAanvragenBetalingInBehandeling = lazy(() => import('pages/sleutel-aanvragen/SleutelAanvragenBetalingInBehandeling'));
const SleutelAanvragenBetalingError = lazy(() => import('pages/sleutel-aanvragen/ErrorBetaling'));
const SleutelAanvragenSuccess = lazy(() => import('pages/sleutel-aanvragen/Success'));
const SleutelAanvragenError = lazy(() => import('pages/sleutel-aanvragen/Error'));

const ServiceSubscription = lazy(() => import('pages/service-subscription/ServiceSubscription'));
const ServiceSubscriptionConfirmation = lazy(() => import('pages/service-subscription/service-subscription-confirmation/ServiceSubscriptionConfirmation'));

const HomeValuePoints = lazy(() => import('pages/home-value-points/HomeValuePoints'));

const CancelContract = lazy(() => import('pages/cancel-contract/CancelContract'));
const CancelContractConfirmation = lazy(() => import('pages/cancel-contract/cancel-contract-confirmation/CancelContractConfirmation'));
const CancelContractError = lazy(() => import('pages/cancel-contract/cancel-contract-error/CancelContractError'));

const PaymentPlan = lazy(() => import('pages/payment-plan/PaymentPlan'));
const PaymentPlanConfirmation = lazy(() => import('pages/payment-plan/payment-plan-confirmation/PaymentPlanConfirmation'));
const PaymentPlanError = lazy(() => import('pages/payment-plan/payment-plan-error/PaymentPlanError'));

const ChangeMaintenanceAppointment = lazy(() => import('pages/change-maintenance-appointment/ChangeMaintenanceAppointment'));
const ChangeMaintenanceAppointmentConfirmation = lazy(() => import('pages/change-maintenance-appointment/change-maintenance-appointment-confirmation/ChangeMaintenanceAppointmentConfirmation'));
const ChangeMaintenanceAppointmentError = lazy(() => import('pages/change-maintenance-appointment/change-maintenance-appointment-error/ChangeMaintenanceAppointmentError'));

const RentalReferenceLetter = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetter'));
const RentalReferenceLetterStart = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterStart'));
const RentalReferenceLetterConfirmation = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterConfirmation'));
const RentalReferenceLetterError = lazy(() => import('pages/rental-reference-letter/RentalReferenceLetterError'));

const RentReductionRequest = lazy(() => import('pages/rent-reduction-request/RentReductionRequest'));
const RequestFormConfirmation = lazy(() => import('pages/rent-reduction-request/request-form/RequestFormConfirmation'));
const RequestFormError = lazy(() => import('pages/rent-reduction-request/request-form/RequestFormError'));

const AdvancementAdjustment = lazy(() => import('pages/advancement-adjustment/AdvancementAdjustment'));
const AdvancementAdjustmentConfirmation = lazy(() => import('pages/advancement-adjustment/advancement-adjustment-confirmation/AdvancementAdjustmentConfirmation'));
const AdvancementAdjustmentError = lazy(() => import('pages/advancement-adjustment/advancement-adjustment-error/AdvancementAdjustmentError'));

const RequestNametag = lazy(() => import('pages/request-nametag/RequestNametag'));
const RequestNametagConfirmation = lazy(() => import('pages/request-nametag/request-nametag-confirmation/RequestNametagConfirmation'));
const RequestNametagError = lazy(() => import('pages/request-nametag/request-nametag-error/RequestNametagError'));

const ChangeDirectDebitDate = lazy(() => import('pages/change-direct-debit-date/ChangeDirectDebitDate'));
const ChangeDirectDebitDateConfirmation = lazy(() => import('pages/change-direct-debit-date/change-direct-debit-date-confirmation/ChangeDirectDebitDateConfirmation'));
const ChangeDirectDebitDateError = lazy(() => import('pages/change-direct-debit-date/change-direct-debit-date-error/ChangeDirectDebitDateError'));

const MailPreferences = lazy(() => import('pages/mail-preferences/MailPreferences'));
const Notifications = lazy(() => import('pages/notifications/Notifications'));
const NoAccess = lazy(() => import('pages/no-access/NoAccess'));
const NotFound = lazy(() => import('pages/not-found/NotFound'));
const PaymentReturn = lazy(() => import('pages/payment-return/PaymentReturn'));

const RentIncreaseObjection = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjection'));
const RentIncreaseObjectionConfirmation = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjectionConfirmation'));
const RentIncreaseObjectionError = lazy(() => import('pages/rent-increase-objection/RentIncreaseObjectionError'));

const MatchmailConsent = lazy(() => import('pages/matchmail-consent/MatchmailConsent'));
const MatchmailConsentConfirmation = lazy(() => import('pages/matchmail-consent/matchmail-consent-confirmation/MatchmailConsentConfirmation'));
const MatchmailConsentError = lazy(() => import('pages/matchmail-consent/matchmail-consent-error/MatchmailConsentError'));

const KlussenInJeWoning = lazy(() => import('pages/klussen-in-je-woning/KlussenInJeWoning'));
const KlussenInJeWoningContract = lazy(() => import('pages/klussen-in-je-woning/contract/KlussenInJeWoningContract'));
const KlussenInJeWoningOutcome = lazy(() => import('pages/klussen-in-je-woning/outcomes/KlussenInJeWoningOutcome'));
const KlussenInJeWoningOther = lazy(() => import('pages/klussen-in-je-woning/outcomes/other/Other'));
const KlussenInJeWoningResult = lazy(() => import('pages/klussen-in-je-woning/result/KlussenInJeWoningResult'));

const HuurcontractKoppelen = lazy(() => import('pages/huurcontract-koppelen/HuurcontractKoppelen'));
const HuurcontractKoppelenConfirmation = lazy(() => import('pages/huurcontract-koppelen/confirmation/HuurcontractKoppelenConfirmation'));

const RepairStatus = lazy(() => import('pages/repair-status/RepairStatus'));

const DocumentView = lazy(() => import('pages/document-view/DocumentView'));

const WoonfraudeMelden = lazy(() => import('pages/woonfraude-melden/WoonfraudeMelden'));
const WoonfraudeMeldenSuccess = lazy(() => import('pages/woonfraude-melden/Success'));
const WoonfraudeMeldenError = lazy(() => import('pages/woonfraude-melden/Error'));

const OngedierteMelden = lazy(() => import('pages/ongedierte-melden/OngedierteMelden'));
const OngedierteMeldenSuccess = lazy(() => import('pages/ongedierte-melden/OngedierteMeldenConfirmation'));
const OngedierteMeldenError = lazy(() => import('pages/ongedierte-melden/OngedierteMeldenError'));

const BetalingsregelingBedrijfsruimte = lazy(() => import('pages/betalingsregeling-bedrijfsruimte/BetalingsregelingBedrijfsruimte'));
const BetalingsregelingBedrijfsruimteConfirmation = lazy(() => import('pages/betalingsregeling-bedrijfsruimte/BetalingsregelingBedrijfsruimteConfirmation'));
const BetalingsregelingBedrijfsruimteError = lazy(() => import('pages/betalingsregeling-bedrijfsruimte/BetalingsregelingBedrijfsruimteError'));

const ZorgOmJeBuur = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuur'));
const ZorgOmJeBuurConfirmation = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuurConfirmation'));
const ZorgOmJeBuurError = lazy(() => import('pages/zorg-om-je-buur/ZorgOmJeBuurError'));

const ChangeIban = lazy(() => import('pages/change-iban/ChangeIban'));
const ChangeIbanSuccess = lazy(() => import('pages/change-iban/ChangeIbanConfirmation'));
const ChangeIbanError = lazy(() => import('pages/change-iban/ChangeIbanError'));

const HuurderVertrekt = lazy(() => import('pages/huurder-vertrekt/HuurderVertrekt'));
const HuurderVertrektSuccess = lazy(() => import('pages/huurder-vertrekt/HuurderVertrektConfirmation'));
const HuurderVertrektError = lazy(() => import('pages/huurder-vertrekt/HuurderVertrektError'));

const ReparatieMelden = lazy(() => import('pages/reparatie-melden/ReparatieMelden'));
const ReparatieMeldenBekendeMeldingen = lazy(() => import('pages/reparatie-melden/bekende-meldingen/BekendeMeldingen'));
const ReparatieMeldenProbleemProfiel = lazy(() => import('pages/reparatie-melden/probleemprofiel/ProbleemProfiel'));
const ReparatieMeldenMelding = lazy(() => import('pages/reparatie-melden/melding/Melding'));
const ReparatieMeldenUitkomst = lazy(() => import('pages/reparatie-melden/uitkomst/Uitkomst'));
const ReparatieMeldenUitkomstError = lazy(() => import('pages/reparatie-melden/uitkomst/UitkomstError'));

const OverlastMelden = lazy(() => import('pages/overlast-melden/OverlastMelden'));
const OverlastMeldenSuccess = lazy(() => import('pages/overlast-melden/Success'));
const OverlastMeldenError = lazy(() => import('pages/overlast-melden/Error'));

const GevelreclameRolluikenZonwering = lazy(() => import('pages/gevelreclame-rolluiken-en-zonwering/GevelreclameRolluikenEnZonwering'));
const GevelreclameRolluikenZonweringSuccess = lazy(() => import('pages/gevelreclame-rolluiken-en-zonwering/GevelreclameRolluikenEnZonweringConfirmation'));
const GevelreclameRolluikenZonweringError = lazy(() => import('pages/gevelreclame-rolluiken-en-zonwering/GevelreclameRolluikenEnZonweringError'));

// Do not forget to add pages to the copy-prerender.js script
// Commented the B2C pages because of the global stylesheet being loaded
export const b2cPages = [
	{
		path: Paths.LOGIN_B2C,
		// component: LoginB2C,
	},
	{
		path: Paths.REGISTER_B2C,
		// component: RegisterB2C,
	},
	{
		path: Paths.FORGOT_PASSWORD_B2C,
		// component: ForgotPasswordB2C,
	},
	{
		path: Paths.CHANGE_PASSWORD_B2C,
		// component: ChangePasswordB2C,
	},
	{
		path: Paths.INVITATION_B2C,
		// component: InvitationB2C,
	},
	{
		path: Paths.PROFILE_UPDATE_B2C,
		// component: ProfileUpdateB2C,
	},
	{
		path: Paths.LOGIN_SIMPLE_B2C,
		// component: LoginSimpleB2C,
	},
];

export const loginPublicRoutes = [
	{
		path: Paths.LOGGED_OUT,
		component: LoggedOut,
		redirectIfLoggedIn: Paths.DASHBOARD,
	},
	{
		path: Paths.LOGIN_FAILED,
		component: LoginFailed,
		redirectIfLoggedIn: Paths.DASHBOARD,
	},
];

export const aliasPublicRoutes: RouteItem[] = [
	{
		path: Paths.CREATE_ACCOUNT,
		to: safeEnv('B2C_CREATE_ACCOUNT') ?? '',
	},
];

export const centerBoxedPublicRoutes = [
	{
		path: Paths.CHOOSE_ACCOUNT,
		component: ChooseAccount,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.MATCHMAIL_CONSENT,
		component: MatchmailConsent,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.MATCHMAIL_CONSENT_CONFIRMATION,
		component: MatchmailConsentConfirmation,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.MATCHMAIL_CONSENT_ERROR,
		component: MatchmailConsentError,
		redirectIfLoggedIn: undefined,
	},

];

export const publicFullPageRoutes = [
	{
		path: Paths.KLUSSEN_IN_JE_WONING,
		component: KlussenInJeWoning,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.KLUSSEN_IN_JE_WONING_CONTRACT,
		component: KlussenInJeWoningContract,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.KLUSSEN_IN_JE_WONING_RESULT,
		component: KlussenInJeWoningResult,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.KLUSSEN_IN_JE_WONING_OTHER_RESULT,
		component: KlussenInJeWoningResult,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.KLUSSEN_IN_JE_WONING_OUTCOME,
		component: KlussenInJeWoningOutcome,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.KLUSSEN_IN_JE_WONING_OTHER,
		component: KlussenInJeWoningOther,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.REPAIR_STATUS,
		component: RepairStatus,
		redirectIfLoggedIn: undefined,
	},
	{
		path: Paths.REPARATIE_MELDEN,
		component: ReparatieMelden,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.REPARATIE_MELDEN_BEKENDE_MELDINGEN,
		component: ReparatieMeldenBekendeMeldingen,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.REPARATIE_MELDEN_PROBLEEMPROFIEL,
		component: ReparatieMeldenProbleemProfiel,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.REPARATIE_MELDEN_MELDING,
		component: ReparatieMeldenMelding,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.REPARATIE_MELDEN_UITKOMST,
		component: ReparatieMeldenUitkomst,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
	{
		path: Paths.REPARATIE_MELDEN_UITKOMST_ERROR,
		component: ReparatieMeldenUitkomstError,
		redirectIfLoggedIn: undefined,
		exact: true,
	},
];

export const noTemplateRoutes = [
	{
		path: Paths.DOCUMENT_VIEW,
		component: DocumentView,
	},
];

type PrivateRoute = {
	readonly path: ValueOf<typeof Paths>;
	readonly component: FunctionComponent;
	readonly featureflag?: {
		readonly featureName: FeatureFlags;
		readonly component: FunctionComponent;
	},
};

const privateRoutesAll: PrivateRoute[] = [
	{
		path: Paths.DASHBOARD,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_WOONRUIMTE,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_PARKEERPLAATS,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_BUSINESS,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_HUUR_BETALINGEN,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_HUISHOUDEN,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_FACTUREN,
		component: Dashboard,
	},
	{
		path: Paths.DASHBOARD_DOCUMENTEN,
		component: Dashboard,
	},
	{
		path: Paths.PAYMENT_RETURN,
		component: PaymentReturn,
	},
	{
		path: Paths.NOTIFICATIONS,
		component: Notifications,
	},
	{
		path: Paths.MAIL_PREFERENCES,
		component: MailPreferences,
	},
	{
		path: Paths.REQUEST_NAMETAG,
		component: RequestNametag,
	},
	{
		path: Paths.REQUEST_NAMETAG_SUCCESS,
		component: RequestNametagConfirmation,
	},
	{
		path: Paths.REQUEST_NAMETAG_ERROR,
		component: RequestNametagError,
	},
	{
		path: Paths.HUURCONTRACT_KOPPELEN,
		component: HuurcontractKoppelen,
	},
	{
		path: Paths.HUURCONTRACT_KOPPELEN_CONFIRMATION,
		component: HuurcontractKoppelenConfirmation,
	},
	{
		path: Paths.CHANGE_EMAIL_MULTI_USER,
		component: ChangeEmailMultiUser,
	},
	{
		path: Paths.CHANGE_EMAIL_MULTI_USER_CONFIRMATION,
		component: ChangeEmailMultiUserConfirmation,
	},
	{
		path: Paths.USER_PROFILE,
		component: UserProfile,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION,
		component: RentIncreaseObjection,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION_CONFIRMATION,
		component: RentIncreaseObjectionConfirmation,
	},
	{
		path: Paths.RENT_INCREASE_OBJECTION_ERROR,
		component: RentIncreaseObjectionError,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN,
		component: OngedierteMelden,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN_SUCCESS,
		component: OngedierteMeldenSuccess,
	},
	{
		path: Paths.ONGEDIERTE_MELDEN_ERROR,
		component: OngedierteMeldenError,
	},
	{
		path: Paths.BETALINGSREGELING_BEDRIJFSRUIMTE,
		component: BetalingsregelingBedrijfsruimte,
	},
	{
		path: Paths.BETALINGSREGELING_BEDRIJFSRUIMTE_CONFIRMATION,
		component: BetalingsregelingBedrijfsruimteConfirmation,
	},
	{
		path: Paths.BETALINGSREGELING_BEDRIJFSRUIMTE_ERROR,
		component: BetalingsregelingBedrijfsruimteError,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR,
		component: ZorgOmJeBuur,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR_SUCCESS,
		component: ZorgOmJeBuurConfirmation,
	},
	{
		path: Paths.ZORG_OM_JE_BUUR_ERROR,
		component: ZorgOmJeBuurError,
	},
	{
		path: Paths.CHANGE_IBAN,
		component: ChangeIban,
	},
	{
		path: Paths.CHANGE_IBAN_SUCCESS,
		component: ChangeIbanSuccess,
	},
	{
		path: Paths.CHANGE_IBAN_ERROR,
		component: ChangeIbanError,
	},
	{
		path: Paths.HUURDER_VERTREKT,
		component: HuurderVertrekt,
	},
	{
		path: Paths.HUURDER_VERTREKT_SUCCESS,
		component: HuurderVertrektSuccess,
	},
	{
		path: Paths.HUURDER_VERTREKT_ERROR,
		component: HuurderVertrektError,
	},
	{
		path: Paths.NO_ACCESS,
		component: NoAccess,
	},
	{
		path: Paths.NOT_FOUND,
		component: NotFound,
	},
];

const privateRoutesContractor: PrivateRoute[] = [
	{
		path: Paths.ADD_ROOMMATE,
		component: AddRoommate,
	},
	{
		path: Paths.ADD_ROOMMATE_CONFIRMATION,
		component: AddRoommateConfirmation,
	},
	{
		path: Paths.ADD_ROOMMATE_ERROR,
		component: AddRoommateError,
	},
	{
		path: Paths.INWONER_VERTREKT,
		component: InwonerVertrekt,
	},
	{
		path: Paths.INWONER_VERTREKT_SUCCESS,
		component: InwonerVertrektSuccess,
	},
	{
		path: Paths.INWONER_VERTREKT_ERROR,
		component: InwonerVertrektError,
	},
	{
		path: Paths.REQUEST_KEY,
		component: RequestKey,
	},
	{
		path: Paths.REQUEST_KEY_PAYMENT_RETURN,
		component: RequestKeyPaymentReturn,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN,
		component: SleutelAanvragenFormKeuze,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_DETAILS,
		component: SleutelAanvragenFormDetails,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_BETALING_IN_BEHANDELING,
		component: SleutelAanvragenBetalingInBehandeling,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_BETALING_ERROR,
		component: SleutelAanvragenBetalingError,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_SUCCESS,
		component: SleutelAanvragenSuccess,
	},
	{
		path: Paths.SLEUTEL_AANVRAGEN_ERROR,
		component: SleutelAanvragenError,
	},
	{
		path: Paths.HOME_VALUATION_POINTS,
		component: HomeValuePoints,
	},
	{
		path: Paths.CANCEL_CONTRACT,
		component: CancelContract,
	},
	{
		path: Paths.CANCEL_CONTRACT_CONFIRMATION,
		component: CancelContractConfirmation,
	},
	{
		path: Paths.CANCEL_CONTRACT_ERROR,
		component: CancelContractError,
	},
	{
		path: Paths.PAYMENT_PLAN,
		component: PaymentPlan,
	},
	{
		path: Paths.PAYMENT_PLAN_SUCCESS,
		component: PaymentPlanConfirmation,
	},
	{
		path: Paths.PAYMENT_PLAN_ERROR,
		component: PaymentPlanError,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE,
		component: ChangeDirectDebitDate,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE_CONFIRMATION,
		component: ChangeDirectDebitDateConfirmation,
	},
	{
		path: Paths.CHANGE_DIRECT_DEBIT_DATE_ERROR,
		component: ChangeDirectDebitDateError,
	},
	{
		path: Paths.CHANGE_MAINTENANCE_APPOINTMENT,
		component: ChangeMaintenanceAppointment,
	},
	{
		path: Paths.CHANGE_MAINTENANCE_APPOINTMENT_SUCCESS,
		component: ChangeMaintenanceAppointmentConfirmation,
	},
	{
		path: Paths.CHANGE_MAINTENANCE_APPOINTMENT_ERROR,
		component: ChangeMaintenanceAppointmentError,
	},
	{
		path: Paths.SERCIVE_SUBSCRIPTION,
		component: ServiceSubscription,
	},
	{
		path: Paths.SERCIVE_SUBSCRIPTION_CONFIRMATION,
		component: ServiceSubscriptionConfirmation,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER,
		component: RentalReferenceLetter,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_START,
		component: RentalReferenceLetterStart,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_SUCCESS,
		component: RentalReferenceLetterConfirmation,
	},
	{
		path: Paths.RENTAL_REFERENCE_LETTER_ERROR,
		component: RentalReferenceLetterError,
	},
	{
		path: Paths.RENT_REDUCTION_REQUEST,
		component: RentReductionRequest,
	},
	{
		path: Paths.RENT_REDUCTION_REQUEST_ERROR,
		component: RequestFormError,
	},
	{
		path: Paths.RENT_REDUCTION_REQUEST_SUCCESS,
		component: RequestFormConfirmation,
	},
	{
		path: Paths.ADVANCEMENT_ADJUSTMENT,
		component: AdvancementAdjustment,
	},
	{
		path: Paths.ADVANCEMENT_ADJUSTMENT_SUCCESS,
		component: AdvancementAdjustmentConfirmation,
	},
	{
		path: Paths.ADVANCEMENT_ADJUSTMENT_ERROR,
		component: AdvancementAdjustmentError,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN,
		component: WoonfraudeMelden,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN_SUCCESS,
		component: WoonfraudeMeldenSuccess,
	},
	{
		path: Paths.WOONFRAUDE_MELDEN_ERROR,
		component: WoonfraudeMeldenError,
	},
	{
		path: Paths.OVERLAST_MELDEN,
		component: OverlastMelden,
	},
	{
		path: Paths.OVERLAST_MELDEN_SUCCESS,
		component: OverlastMeldenSuccess,
	},
	{
		path: Paths.OVERLAST_MELDEN_ERROR,
		component: OverlastMeldenError,
	},
	{
		path: Paths.GEVELRECLAME_ROLLUIKEN_ZONWERING,
		component: GevelreclameRolluikenZonwering,
	},
	{
		path: Paths.GEVELRECLAME_ROLLUIKEN_ZONWERING_SUCCESS,
		component: GevelreclameRolluikenZonweringSuccess,
	},
	{
		path: Paths.GEVELRECLAME_ROLLUIKEN_ZONWERING_ERROR,
		component: GevelreclameRolluikenZonweringError,
	},
];

const LoginPublicRoutes = () => {
	return (
		<>
			<AuthPageTransition>
				{loginPublicRoutes.map(({ path, component }) => (
					<Route
						key={path}
						path={path}
						component={component}
						exact
					/>
				))}
			</AuthPageTransition>
		</>
	);
};

const PublicRoutes = () => {
	return (
		<CenterBoxedContainer>
			<AuthPageTransition>
				{centerBoxedPublicRoutes.map(({ path, component }) => (
					<Route
						key={path}
						path={path}
						component={component}
						exact
					/>
				))}
			</AuthPageTransition>
		</CenterBoxedContainer>
	);
};

const PrivateRoutes = () => {
	const featureFlagsPresent = useFeatureFlagsPresent();
	const contractor = useUserIsContractor();

	return (
		<PageRoutesContainer>
			{privateRoutesAll.map(({ path, component, featureflag }) => (
				<Route
					key={path}
					path={path}
					component={
						featureflag && featureFlagsPresent.includes(featureflag?.featureName)
							? featureflag?.component : component
					}
					exact
				/>
			))}

			{privateRoutesContractor.map(({ path, component }) => (
				<Route
					key={path}
					path={path}
					component={contractor ? component : NoAccess}
					exact
				/>
			))}

		</PageRoutesContainer>
	);
};

const PublicFullPageRoutes = () => {
	return (
		<PageRoutesContainer>
			{publicFullPageRoutes.map((route) => (
				<Route
					key={route.path}
					path={route.path}
					component={route.component}
					exact={route.exact}
				/>
			))}
		</PageRoutesContainer>
	);
};

const publicRoutesPaths = centerBoxedPublicRoutes.map(({ path }) => path);
const loginPublicRoutesPaths = loginPublicRoutes.map(({ path }) => path);
const aliasPublicRoutesPaths = aliasPublicRoutes.map(({ path }) => path);
const publicFullPagePaths = [...publicFullPageRoutes].map(({ path }) => path).flat();
export const allPublicPaths = [...publicRoutesPaths, ...loginPublicRoutesPaths, ...publicFullPagePaths, ...aliasPublicRoutesPaths];
export const allPrivatePaths: ValueOf<typeof Paths>[] = [...privateRoutesAll, ...privateRoutesContractor].map(({ path }) => path).flat();

const AppRoutes = () => {
	useMultiCustomerRouting();
	const { hasCallback } = useRedirectCallback();
	useScrollRestoration();
	useGoogleTagManagerVirtualPageView();
	useAliasRouting(aliasPublicRoutes);

	const location = useLocation();

	// This prevents the page from rendering when the user is redirected after login
	// When page is external - a flash of the 404 page is shown if this check is not here
	if (hasCallback) {
		return null;
	}

	const knownPaths = [...publicFullPagePaths, ...allPublicPaths, ...allPrivatePaths];

	const changeAppointmentRoute = location.pathname.startsWith('/afspraak-reparatieverzoek-aanpassen/');
	const klussenInJeWoningRoute = location.pathname.startsWith('/klussen-in-je-woning/');
	const routeArray = location.pathname.split('/');

	// Check if not found page should be shown when not correct path for routes if id's
	const isChangeAppointmentRouteAllowed = changeAppointmentRoute && routeArray.length === 3 || routeArray.length === 4 && routeArray[3] === 'succes' || routeArray[3] === 'mislukt';
	const isKlussenInJeWoningRouteAllowed = klussenInJeWoningRoute && routeArray.length < 5 || routeArray.length === 5 && routeArray[4] === 'succes';

	return (
		<LanguageProvider>
			<Switch>
				{noTemplateRoutes.map(({ path, component }) => (
					<Route
						key={path}
						path={path}
						component={component}
						exact
					/>
				))}
				{/* Checking <Route path='*'>... doesn't work properly so we check if the current path is one of the known paths, if not show not found  */}
				{knownPaths.includes(location.pathname) === false && !isChangeAppointmentRouteAllowed && !isKlussenInJeWoningRouteAllowed && <PageRoutesContainer><Route component={NotFound} /></PageRoutesContainer>}
				<Route
					path={publicRoutesPaths}
					component={PublicRoutes}
				/>
				<Route
					path={loginPublicRoutesPaths}
					component={LoginPublicRoutes}
				/>
				<Route
					path={publicFullPagePaths}
					component={PublicFullPageRoutes}
					exact={true}
				/>
				<Route
					path={allPrivatePaths}
					component={PrivateRoutes}
				/>

			</Switch>
			<InstallPwa />
		</LanguageProvider>
	);
};

const b2cPaths = b2cPages.map(({ path }) => path);

const Routing = () => {
	const isOnB2CLogin = useRouteMatch(b2cPaths)?.isExact;

	return (
		<Switch>
			{!isOnB2CLogin && (
				<AppRoutes />
			)}
		</Switch>
	);
};

export default Routing;
