import { safeEnv } from './safeEnv';

const externalLink = {
	WEBSITE: {
		tst: 'https://www-tst.woonstadrotterdam.nl',
		acc: 'https://www-acc.woonstadrotterdam.nl',
		prd: 'https://www.woonstadrotterdam.nl',
	},
	OFFERS: {
		tst: 'https://www-tst.woonstadrotterdam.nl/aanbod',
		acc: 'https://www-acc.woonstadrotterdam.nl/aanbod',
		prd: 'https://www.woonstadrotterdam.nl/aanbod',
	},
	REPARATION_REQUEST: {
		tst: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		acc: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
		prd: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen',
	},
	REPARATION_REQUEST_STADSWONEN: {
		tst: 'https://www-tst.stadswonenrotterdam.nl/nl/klantenservice/reparaties-onderhoud-en-klussen/reparatie-melden', // page not added yet
		acc: 'https://www-acc.stadswonenrotterdam.nl/nl/klantenservice/reparaties-onderhoud-en-klussen/reparatie-melden',
		prd: 'https://www.stadswonenrotterdam.nl/nl/klantenservice/reparaties-onderhoud-en-klussen/reparatie-melden',
	},
	REPARATION_REQUEST_BUSINESS: {
		tst: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen/ik-huur-een-bedrijfsruimte',
		acc: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen/ik-huur-een-bedrijfsruimte',
		prd: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/reparatie-en-onderhoud/reparatie-aanvragen/ik-huur-een-bedrijfsruimte',
	},
	PAY_RENT: {
		tst: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
		acc: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
		prd: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/betalen-en-geldzaken/huur-betalen',
	},
	COMPLAINT_OR_ANNOYANCE: {
		tst: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen/klacht-of-overlast/overlast',
		acc: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen/klacht-of-overlast/overlast',
		prd: 'https://www.woonstadrotterdam.nl/informatie-en-regelen/klacht-of-overlast/overlast',
	},
	DISCLAIMER: {
		tst: 'https://www-tst.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
		acc: 'https://www-acc.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
		prd: 'https://www.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5dd55ed7c3f0cd1610bd75f2/file',
	},
	SERVICE_SUBSCRIPTION: {
		tst: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
		acc: 'https://www-acc.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
		prd: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d80eb9b0c51611318980906',
	},
	CANCEL_CONTRACT_INSTRUCTIONS: {
		tst: 'https://www-tst.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5e4e8e88b9ef651730690b7d/file',
		acc: 'https://www-acc.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5e4e8e88b9ef651730690b7d/file',
		prd: 'https://www.woonstadrotterdam.nl/api/Knowledge/Media/external/media/5e4e8e88b9ef651730690b7d/file',
	},
	RENT_CANCELED_INSTRUCTIONS: {
		tst: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d778ed40c5161131898050e',
		acc: 'https://www-acc.woonstadrotterdam.nl/kennisbank-artikel/5d778ed40c5161131898050e',
		prd: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d778ed40c5161131898050e',
	},
	USER_CONSENT_DISCLAIMER: {
		tst: 'https://mijn-tst.woonstadrotterdam.nl/Resources/Documents/Gebruikersvoorwaarden-MijnWoonstad.pdf',
		acc: 'https://mijn-acc.woonstadrotterdam.nl/Resources/Documents/Gebruikersvoorwaarden-MijnWoonstad.pdf',
		prd: 'https://mijn.woonstadrotterdam.nl/Resources/Documents/Gebruikersvoorwaarden-MijnWoonstad.pdf',
	},
	RENT_REDUCTION_REQUEST_NEWS_INFORMATION: {
		tst: 'https://www-tst.woonstadrotterdam.nl/nieuws/eenmalige-huurverlaging-2021',
		acc: 'https://www-acc.woonstadrotterdam.nl/nieuws/eenmalige-huurverlaging-2021',
		prd: 'https://www.woonstadrotterdam.nl/nieuws/eenmalige-huurverlaging-2021',
	},
	RENT_REDUCTION_REQUEST_INFORMATION: {
		tst: 'https://www-tst.woonstadrotterdam.nl/huurverlaging',
		acc: 'https://www-acc.woonstadrotterdam.nl/huurverlaging',
		prd: 'https://www.woonstadrotterdam.nl/huurverlaging',
	},
	RENT_REDUCTION_REQUEST_REQUIREMENTS: {
		tst: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d8a0c3107badd23fcd58f21',
		acc: 'https://www-acc.woonstadrotterdam.nl/kennisbank-artikel/5d8a0c3107badd23fcd58f21',
		prd: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d8a0c3107badd23fcd58f21',
	},
	HOME_VALUATION_NEWS_INFORMATION: {
		tst: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
		acc: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
		prd: 'https://www.woonstadrotterdam.nl/nieuws/wat-is-woningwaardering',
	},
	PRIVACY_STATEMENT: {
		tst: 'https://www-tst.woonstadrotterdam.nl/privacy-en-cookie-statement',
		acc: 'https://www-acc.woonstadrotterdam.nl/privacy-en-cookie-statement',
		prd: 'https://www.woonstadrotterdam.nl/privacy-en-cookie-statement',
	},
	LOGIN_VVE: {
		tst: 'https://woonstadrotterdam.twinq.nl/',
		acc: 'https://woonstadrotterdam.twinq.nl/',
		prd: 'https://woonstadrotterdam.twinq.nl/',
	},
	LOGIN_PARTNER: {
		tst: 'https://wocas.woonstadrotterdam.nl/',
		acc: 'https://wocas.woonstadrotterdam.nl/',
		prd: 'https://wocas.woonstadrotterdam.nl/',
	},
	LOGIN_EMPLOYEE: {
		tst: 'https://www-tst.woonstadrotterdam.nl/contact/extranet',
		acc: 'https://www-acc.woonstadrotterdam.nl/contact/extranet',
		prd: 'https://www.woonstadrotterdam.nl/contact/extranet',
	},
	CONTACT: {
		tst: 'https://www-tst.woonstadrotterdam.nl/contact',
		acc: 'https://www-acc.woonstadrotterdam.nl/contact',
		prd: 'https://www.woonstadrotterdam.nl/contact',
	},
	INFO: {
		tst: 'https://www-tst.woonstadrotterdam.nl/informatie-en-regelen',
		acc: 'https://www-acc.woonstadrotterdam.nl/informatie-en-regelen',
		prd: 'https://www.woonstadrotterdam.nl/informatie-en-regelen',
	},
	WOONNET_RIJNMOND_LOGIN: {
		tst: 'https://www.woonnetrijnmond.nl/inloggeninschrijven',
		acc: 'https://www.woonnetrijnmond.nl/inloggeninschrijven',
		prd: 'https://www.woonnetrijnmond.nl/inloggeninschrijven',
	},
	WOONFRAUDE: {
		tst: 'https://www-tst.woonstadrotterdam.nl/kennisbank-artikel/5d6fabdb2f9f273cb444a57c',
		acc: 'https://www-acc.woonstadrotterdam.nl/Welkom/kennisbank-artikel/5d6fabdb2f9f273cb444a57c',
		prd: 'https://www.woonstadrotterdam.nl/kennisbank-artikel/5d6fabdb2f9f273cb444a57c',
	},
	INWONER_VERTREKT: {
		tst: 'https://www.rotterdam.nl/verhuizing-doorgeven',
		acc: 'https://www.rotterdam.nl/verhuizing-doorgeven',
		prd: 'https://www.rotterdam.nl/verhuizing-doorgeven',
	},
} as const;

type ExternalLinkKeys = keyof typeof externalLink;

export const getExternalLink = (link: ExternalLinkKeys, env?: string): string => {
	switch (safeEnv('ENVIRONMENT') || env) {
		case 'Production':
			return externalLink[link].prd;
		case 'Acceptance':
			return externalLink[link].acc;
		case 'Test':
			return externalLink[link].tst;

		default:
			return externalLink[link].prd;
	}
};
